import * as React from 'react';
import { useContext, useEffect } from 'react';
import cx from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';

import * as s from './AnimatedLine.module.sass';

type AnimatedLineProps = {
  align?: keyof typeof alignClass
  verticalAlign?: keyof typeof verticalAlignClass
  direction?: keyof typeof directionClass
  duration?: number
  delay?: number
  fromStart?: boolean
  className?: string
};

const directionClass = {
  horizontal: s.horizontal,
  vertical: s.vertical,
};

const verticalAlignClass = {
  top: s.top,
  bottom: s.bottom,
};

const alignClass = {
  left: s.left,
  right: s.right,
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const AnimatedLine: React.FC<AnimatedLineProps> = ({
  direction = 'vertical',
  align = 'left',
  verticalAlign = 'top',
  duration = 1.2,
  delay = .3,
  fromStart = false,
  className,
}) => {
  const { colorThemeMode } = useContext(ColorThemeContext);

  const classNames = cx(
    s.root,
    modeClass[colorThemeMode],
    directionClass[direction],
    alignClass[align],
    verticalAlignClass[verticalAlign],
    className,
  );

  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView || fromStart) {
      controls.start(
        direction === 'horizontal'
          ? { scaleX: 1 }
          : { scaleY: 1 },
      );
    }
  }, [controls, direction, fromStart, inView]);

  return (
    <motion.div
      ref={ref}
      className={classNames}
      style={{ originX: 0, originY: 0 }}
      initial={
        direction === 'horizontal'
          ? { scaleX: 0 }
          : { scaleY: 0 }
      }
      animate={controls}
      transition={{
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: duration,
        delay: delay,
      }}
    />
  );
};