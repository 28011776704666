// extracted by mini-css-extract-plugin
export var root = "Header-module--root--1HeVy";
export var isFixed = "Header-module--isFixed--36Zcg";
export var container = "Header-module--container--2OlIh";
export var logoWrapper = "Header-module--logoWrapper--29Qrk";
export var logo = "Header-module--logo--2VhGR";
export var nav = "Header-module--nav--1zigM";
export var link = "Header-module--link--1FalZ";
export var contactWrapper = "Header-module--contactWrapper--340y_";
export var button = "Header-module--button--jOCcX";
export var buttonArrow = "Header-module--buttonArrow--36nv4";
export var navMyProject = "Header-module--navMyProject--2UOMM";
export var light = "Header-module--light--ocEdD";
export var dark = "Header-module--dark--1Il-Z";