import React, { useEffect, useState } from 'react';

const COLOR_MODE_STORAGE_KEY = 'COLOR_THEME_MODE';

export enum ColorModes {
  Light = 'light',
  Dark = 'dark',
}

type ThemeColorsPros = {
  primary: string
  main: string
};

const themeColorsObj = {
  light: {
    primary: '#1F1F1F',
    main: '#EFEFEF',
  },
  dark: {
    primary: '#EFEFEF',
    main: '#1F1F1F',
  },
};

type ColorThemeContextValue = {
  colorThemeMode: ColorModes
  themeColors: ThemeColorsPros
  setColorThemeMode: () => void
};

export const defaultDataContext: ColorThemeContextValue = {
  colorThemeMode: ColorModes.Light,
  themeColors: themeColorsObj.light,
  setColorThemeMode: () => {},
};

export const ColorThemeContext = React.createContext<ColorThemeContextValue>(defaultDataContext);

export const ColorThemeProvider: React.FC = ({ children }) => {
  const [colorThemeMode, setColorThemeMode] = useState(ColorModes.Light);
  const [themeColors, setThemeColors] = useState(themeColorsObj.light);

  const setMode = (mode: ColorModes) => {
    window.localStorage.setItem(COLOR_MODE_STORAGE_KEY, mode);
    setColorThemeMode(mode);
  };

  const toggleColorTheme = () => {
    if (colorThemeMode === ColorModes.Light) {
      setMode(ColorModes.Dark);
    } else {
      setMode(ColorModes.Light);
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem(COLOR_MODE_STORAGE_KEY) as ColorModes;
    if (localTheme) {
      setColorThemeMode(localTheme);
    } else {
      setMode(ColorModes.Light);
    }
  }, []);

  useEffect(() => {
    if (colorThemeMode === ColorModes.Dark) {
      document.querySelector('html')?.classList.add(ColorModes.Dark);
      setThemeColors(themeColorsObj.dark);
    } else {
      document.querySelector('html')?.classList.remove(ColorModes.Dark);
      setThemeColors(themeColorsObj.light);
    }
  }, [colorThemeMode]);

  return (
    <ColorThemeContext.Provider value={{
      colorThemeMode,
      themeColors,
      setColorThemeMode: toggleColorTheme,
    }}
    >
      {children}
    </ColorThemeContext.Provider>
  );
};
