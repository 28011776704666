import * as React from 'react';
import { ForwardedRef } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';

import { CursorProvider } from '@providers/CursorProvider';
import { ColorThemeProvider } from '@providers/ColorThemeContext';
import { Footer } from '@components/common/Footer';
import { SideLine } from '@components/common/SideLine';
import { Header, NavLinksSections } from '@components/common/Header';
// @ts-ignore
import ogImage from '@images/og_image.jpg';

import * as s from './BaseLayout.module.sass';

const siteMetadata = {
  title: 'Ilona K: Website copywriter, Content creator, Branding strategist',
  description: 'I help businesses and entrepreneurs with website copywriting, content creation, branding strategy while converting qualifying leads into customers.',
  author: 'OlehKhalin',
  lang: 'en',
  image: ogImage,
  url: 'https://ilonakcopywrite.com/',
};

type BaseLayoutProps = {
  seo?: {
    title?: string
    description?: string
  }
  isFixedHeader?: boolean
  scrollToSection?: (section: NavLinksSections) => void
  hiddenHeader?: boolean
  footerRef?: ForwardedRef<HTMLElement>
  className?: string
};

export const BaseLayout: React.FC<BaseLayoutProps> = ({
  seo,
  isFixedHeader = false,
  scrollToSection,
  hiddenHeader = false,
  footerRef,
  className,
  children,
}) => (
  <ColorThemeProvider>
    <CursorProvider>
      <Helmet
        htmlAttributes={{ lang: siteMetadata.lang }}
        title={siteMetadata.title}
        titleTemplate={siteMetadata.title}
        meta={[
          {
            name: 'description',
            content: seo?.description ?? siteMetadata.description,
          },
          {
            name: 'image',
            content: siteMetadata.image,
          },
          {
            property: 'og:title',
            content: seo?.title ?? siteMetadata.title,
          },
          {
            property: 'og:description',
            content: seo?.description ?? siteMetadata.description,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'og:image',
            content: siteMetadata.image,
          },
          {
            name: 'og:url',
            content: siteMetadata.url,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:creator',
            content: siteMetadata.author,
          },
          {
            name: 'twitter:title',
            content: seo?.title ?? siteMetadata.title,
          },
          {
            name: 'twitter:description',
            content: seo?.description ?? siteMetadata.description,
          },
          {
            name: 'twitter:image',
            content: siteMetadata.image,
          },
        ]}
      >
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-1J4QWPEG7N" />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-1J4QWPEG7N');
          `}
        </script>
      </Helmet>
      <div className={s.noise} />
      {!hiddenHeader && <Header isFixed={isFixedHeader} scrollToSection={scrollToSection} />}
      <SideLine />
      <main className={cx(s.root, { [s.isFixedHeader]: isFixedHeader }, className)}>
        {children}
      </main>
      <Footer ref={footerRef} />
    </CursorProvider>
  </ColorThemeProvider>
);