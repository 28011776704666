import React, { useEffect, useState } from 'react';
import { motion, useAnimation, useMotionValue, useSpring } from 'framer-motion';

import * as s from './CursorProvider.module.sass';

export enum CursorTypes {
  default = 'DEFAULT',
  pointer = 'POINTER',
}
type InitialDataContextValue = {
  type: CursorTypes
  toggleCursorType: (cursor: CursorTypes) => void
};
export const defaultDataContext: InitialDataContextValue = {
  type: CursorTypes.default,
  toggleCursorType: () => {},
};
export const CursorContext = React.createContext<InitialDataContextValue>(defaultDataContext);

const cursors = {
  default: {
    type: CursorTypes.default,
    size: {
      width: 16,
      height: 16,
    },
  },
  pointer: {
    type: CursorTypes.pointer,
    size: {
      width: 58,
      height: 58,
      borderRadius: '50%',
    },
    colors: {
      border: 'rgba(200, 155, 85, .5)',
      background: 'transparent',
    },
  },
};

export const CursorProvider: React.FC = ({ children }) => {
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);
  const springConfig = { damping: 45, stiffness: 700 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);
  const [cursor, setCursor] = useState(cursors.default);
  const controls = useAnimation();

  const toggleCursorType = (cursorType: CursorTypes) => {
    if (cursorType === CursorTypes.pointer) {
      setCursor(cursors.pointer);
    } else {
      setCursor(cursors.default);
    }
  };

  useEffect(() => {
    const moveCursor = (e: MouseEvent) => {
      cursorY.set(
        e.clientY - cursor.size.width / 2,
      );
      cursorX.set(
        e.clientX - cursor.size.height / 2,
      );
    };

    window.addEventListener('mousemove', moveCursor);
    return () => {
      window.removeEventListener('mousemove', moveCursor);
    };
  }, [cursor, cursorX, cursorY]);

  useEffect(() => {
    const clickCursor = () => {
      controls.start({
        scale: [1, cursor.type === CursorTypes.default ? 1.4 : 1.2, .7, 1],
        transition: {
          ease: [0.6, 0.01, -0.05, 0.95],
          duration: .3,
        },
      });
    };

    window.addEventListener('click', clickCursor);
    return () => window.removeEventListener('click', clickCursor);
  }, [controls, cursor.type]);

  return (
    <CursorContext.Provider value={{ type: cursor.type, toggleCursorType }}>
      <motion.div
        className={s.cursor}
        style={{
          translateX: cursorXSpring,
          translateY: cursorYSpring,
          width: cursor.size.width,
          height: cursor.size.height,
        }}
        animate={controls}
      />
      {children}
    </CursorContext.Provider>
  );
};
