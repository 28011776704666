import * as React from 'react';
import { forwardRef, useContext } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Container } from '@components/common/Container';

import { Creator } from './Creator';
import * as s from './Footer.module.sass';

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const Footer = forwardRef<HTMLElement>((_, ref) => {
  const { toggleCursorType } = useContext(CursorContext);
  const { colorThemeMode } = useContext(ColorThemeContext);

  return (
    <footer ref={ref} className={cx(s.root, modeClass[colorThemeMode])}>
      <Container className={s.container}>
        <div className={s.content}>
          <Link
            to='/privacy-policy'
            className={s.privacy}
            onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
            onMouseLeave={() => toggleCursorType(CursorTypes.default)}
          >
            Privacy Policy
          </Link>
          <div>
            © {new Date().getFullYear()} Ilona Kravchenko
          </div>
        </div>
        <a
          href="https://artkhalin.com/"
          target='_blank'
          rel="noreferrer noopener"
          className={s.creator}
          onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
          onMouseLeave={() => toggleCursorType(CursorTypes.default)}
          aria-label="O. Khalin by artKhalin with love"
        >
          <Creator className={s.creatorIcon} />
        </a>
      </Container>
    </footer>
  );
});