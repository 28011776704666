import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { motion, useTransform, useViewportScroll } from 'framer-motion';

import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';

import * as s from './SideLine.module.sass';

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const SideLine: React.FC = () => {
  const { scrollY } = useViewportScroll();
  const { colorThemeMode } = useContext(ColorThemeContext);

  const [windowHeight, setWindowHeight] = useState(0);

  useEffect(() => {
    const setValues = () => {
      setWindowHeight(window.document.body.offsetHeight);
    };

    setValues();
    document.addEventListener('load', setValues);
    window.addEventListener('resize', setValues);

    return () => {
      document.removeEventListener('load', setValues);
      window.removeEventListener('resize', setValues);
    };
  });

  const transformAnimationRange = [0, windowHeight];

  const textLineTransform = useTransform(scrollY, transformAnimationRange, [-2_000, 0]);

  return (
    <div className={cx(s.root, modeClass[colorThemeMode])}>
      <div className={s.helper}/>
      <Container className={s.container}>
        <AnimatedLine duration={1.4} delay={1.1} align='right' fromStart />
      </Container>
      <motion.div
        className={s.wrapper}

        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: .8,
          delay: 2,
          ease: [0.6, 0.01, -0.05, 0.95],
        }}
      >
        <motion.div
          initial={{
            x: 60,
            opacity: 0,
          }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            duration: .8,
            delay: 2.6,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}

          style={{ y: textLineTransform }}
        >
        {
          [0, 1, 2, 3, 4, 5].map((el) => (
            <div key={el} className={s.line}>
              <img
                src='/Kravchenko.svg'
                alt="Ilona Kravchenko"
                width='107'
                height='619'
                className={s.lightImage}
              />
              <img
                src='/KravchenkoDark.svg'
                alt="Ilona Kravchenko"
                width='107'
                height='619'
                className={s.darkImage}
              />
            </div>
          ))
        }
        {
          [0, 1, 2].map((el) => (
            <div key={el} className={cx(s.line, s.lineMobile)}>
              <img
                src='/Kravchenko.svg'
                alt="Ilona Kravchenko"
                width='107'
                height='619'
                className={s.lightImage}
              />
              <img
                src='/KravchenkoDark.svg'
                alt="Ilona Kravchenko"
                width='107'
                height='619'
                className={s.darkImage}
              />
            </div>
          ))
        }
      </motion.div>
      </motion.div>
    </div>
  );
};