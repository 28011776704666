import * as React from 'react';
import { useContext } from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorThemeContext, ColorModes } from '@providers/ColorThemeContext';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';
import Logo from '@icons/Logo.inline.svg';
import LinkArrow from '@icons/LinkArrow.inline.svg';

import * as s from './Header.module.sass';

export enum NavLinksSections {
  FirstScreenSection,
  HowCanIHelpSection,
  AboutSection,
  ProjectSection,
  ServicesSection,
  ContactSection,
}

const Navigation = [
  {
    id: 0,
    label: 'How can I help?',
    name: NavLinksSections.HowCanIHelpSection,
  },
  {
    id: 1,
    label: 'About me',
    name: NavLinksSections.AboutSection,
  },
  {
    id: 2,
    label: 'My project',
    name: NavLinksSections.ProjectSection,
  },
  {
    id: 3,
    label: 'Services',
    name: NavLinksSections.ServicesSection,
  },
];

type HeaderProps = {
  isFixed: boolean
  scrollToSection?: (section: NavLinksSections) => void
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const Header: React.FC<HeaderProps> = ({
  isFixed,
  scrollToSection,
}) => {
  const { toggleCursorType } = useContext(CursorContext);
  const { colorThemeMode } = useContext(ColorThemeContext);

  return (
    <header className={cx(s.root, modeClass[colorThemeMode], { [s.isFixed]: isFixed })}>
      <Container className={s.container}>
        <motion.button
          className={s.logoWrapper}
          type='button'
          onClick={scrollToSection && (() => scrollToSection(NavLinksSections.FirstScreenSection))}
          onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
          onMouseLeave={() => toggleCursorType(CursorTypes.default)}
          aria-label="Ilona Kravchenko"

          initial={{
            y: -50,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: .5,
            delay: 1.2,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}
        >
          <Logo className={s.logo} />
        </motion.button>
        <motion.nav
          className={s.nav}

          initial={{
            y: -50,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: .8,
            delay: 1.4,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}
        >
          {Navigation.map(({ id, label, name }) => (
            <button
              key={id}
              type='button'
              onClick={scrollToSection && (() => scrollToSection(name))}
              className={cx(s.link, { [s.navMyProject]: name === NavLinksSections.ProjectSection })}
              onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
              onMouseLeave={() => toggleCursorType(CursorTypes.default)}
            >
              {label}
            </button>
          ))}
        </motion.nav>
        <div className={s.contactWrapper}>
          <AnimatedLine duration={.6} delay={1.8} fromStart />
          <motion.button
            initial={{
              y: -50,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              duration: .5,
              delay: 1.8,
              ease: [0.6, 0.01, -0.05, 0.95],
            }}

            type='button'
            onClick={scrollToSection && (() => scrollToSection(NavLinksSections.ContactSection))}
            className={s.button}
            onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
            onMouseLeave={() => toggleCursorType(CursorTypes.default)}
          >
            Contact me
            <LinkArrow className={s.buttonArrow} />
          </motion.button>
        </div>
      </Container>
      <AnimatedLine direction='horizontal' verticalAlign='bottom' duration={1.2} delay={1} fromStart />
    </header>
  );
};